<template>
  <div>
    <div class="mb-4">
      <div class="mb-4">
        <h2 class="font-weight-bold black--text">
          E-Learning Matematika
        </h2>
        <v-divider></v-divider>
      </div>
      <div
        v-for="section in sections"
        :key="section.title"
        class="mb-4"
      >
        <div class="mb-4">
          <h2 class="font-weight-bold black--text">
            {{ section.title }} - {{ section.subtitle }}
          </h2>
        </div>
        <v-row class="mb-4">
          <v-col
            v-for="item in section.exercises"
            :key="item"
            md="3"
            sm="12"
            cols="12"
          >
            <v-card
              class="pa-4"
              color="#EE3E23"
              height="100%"
              :to="item.link"
            >
              <v-img
                :src="item.icon"
                width="50"
                class="mx-auto"
              ></v-img>
              <v-card-text class="text-center">
                <h2 class="font-weight-light white--text">
                  Latihan
                </h2>
                <h4 class="font-weight-light white--text">
                  {{ item.title }}
                </h4>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </div>
    </div>
  </div>
</template>

<script>
// import iconMedalExercise from '../../../assets/images/elearning/teacher/subject/section/chapter/exercise/1.png'
// import iconMedalExam from '../../../assets/images/exam/1.png'

export default {
  data() {
    return {
      sections: [
        {
          title: 'BAB 1',
          subtitle: 'Operasi Hitungan Bilangan Bulat',
          exercises: [
            {
              icon: iconMedalExercise,
              title: 'Latihan Operasi Berhitung',
              link: '/elearning-parent/subject/section/elearning-result',
            },
            {
              icon: iconMedalExercise,
              title: 'Latihan Perkalian',
              link: '/elearning-parent/subject/section/elearning-result',
            },
          ],
        },
        {
          title: 'BAB II',
          subtitle: 'Geometri 2 Dimensi',
          exercises: [
            {
              icon: iconMedalExercise,
              title: 'Latihan Berhitung Geometri',
            },
          ],
          exams: [
            {
              icon: iconMedalExam,
              title: 'Ujian Operasi Berhitung',
            },
          ],
        },
      ],
    }
  },
}
</script>

<style>
</style>
